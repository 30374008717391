import { NgModule } from '@angular/core';
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenu,
  IonRow,
  IonToolbar
} from '@ionic/angular/standalone';
import { AsyncPipe, NgIf } from '@angular/common';

import { SideMenuComponent } from './side-menu.component';
import { ProfileNavListComponentModule } from '../profile-nav-list/profile-nav-list.module';
import { TranslocoDirective } from '@jsverse/transloco';
import { AvatarUrlPipe } from 'src/app/pipes/avatar-url/avatar-url.pipe';


@NgModule({
  imports: [
    ProfileNavListComponentModule,
    TranslocoDirective,
    AvatarUrlPipe,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonRow,
    IonCol,
    IonAvatar,
    IonButton,
    IonIcon,
    IonContent,
    AsyncPipe,
    NgIf
  ],
  declarations: [SideMenuComponent],
  exports: [SideMenuComponent]
})
export class SideMenuComponentModule {}
