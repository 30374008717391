<ion-menu
        side="end"
        type="overlay"
        [menuId]="menuTagId"
        contentId="main-content"
        [disabled]="! authDataService.isLoggedIn()"
>
  <ion-header>
    <ion-toolbar class="ion-flex ion-flex-middle">
      <ion-row>
        <ion-col
                *ngIf="profileDataService.profile$ | async as profile"
                class="ion-flex ion-flex-middle ion-flex-left"
                size="8"
        >
          <div class="avatar-loggedin">
            <ion-avatar>
              <img
                      *transloco="let t"
                      [src]="profile | avatarUrl"
                      [alt]="t('imageAlt.avatar')"
              />
            </ion-avatar>
          </div>
          <h5>
            {{profile.first_name}}
          </h5>
        </ion-col>
        <ion-col class="ion-flex ion-flex-middle ion-flex-right" size="4">
          <ion-button class="ion-no-margin ion-no-padding" fill="clear" (click)="closeSideMenu()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-toolbar>
  </ion-header>
  <ion-content class="side-menu">
    <app-profile-nav-list (navigationSelected)="closeSideMenu()"></app-profile-nav-list>
  </ion-content>
</ion-menu>
