import { Component } from '@angular/core';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { ProfileDataService } from 'src/app/services/crowdbuilding-cms/services/data/personal/profile.data.service';
import { AuthDataService } from 'src/app/services/crowdbuilding-cms/services/data/personal/auth.data.service';
import { MenuTagIdEnum } from '../../enums/menu-tag-id.enum';
import { MenuController } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { close } from 'ionicons/icons';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'image-alt' }],
})
export class SideMenuComponent {

  public menuTagId = MenuTagIdEnum.PROFILE_SIDE_MENU;

  constructor(
    public authDataService: AuthDataService,
    public profileDataService: ProfileDataService,
    private menuController: MenuController
  ) {
    addIcons({ close });
  }

  public closeSideMenu() {
    this.menuController.close(this.menuTagId);
  }

}
